.span {
  color: blue;
  text-decoration: underline;
}
.upperResults {
  background-color: #f3f4f6;
  padding: 1.5rem;
 
  border-bottom: 1px solid #e5e7eb;
}

a {
  color: #111827;

  text-decoration: none;
  font-weight: 500;
}

.innerResult {
  max-width: 850px;

  margin: 0 auto;
}

.outerTitle {
  margin-bottom: 1.5rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.title h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.title div {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.title p {
  margin: 0;
  padding: 0.25rem 0.75rem;
  background-color: #e5e7eb;
  border-radius: 1rem;
  font-size: 1.5rem;
  color: #4b5563;
}

.filters {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filters > p {
  margin: 0 0 1rem 0;
  font-weight: 500;
  color: #374151;
}

/* Main filter container */
.filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1rem;
  max-width: 100%;
}

/* Individual box styling */
.box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Label styling */
.box span {
  font-size: 0.875rem;
  color: #4b5563;
  font-weight: 500;
}

/* Select container */
.filterBox {
  width: 100%;
}

/* Select input styling */
.filterBox select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background-color: white;
  color: #374151;
  font-size: 0.875rem;
  transition: all 0.2s ease-in-out;
}

/* Select focus state */
.filterBox select:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
}

/* Reset button container */
.filter > div:last-child {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  margin-left: 0;
}

/* Reset button styling */
.reset-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .filter {
    grid-template-columns: 1fr;
  }
}
.outerSearchResult {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerSearchResult {
  /* on desktop  */
  width: 850px;
}
/* 100%  for mobiles using media query*/
@media screen and (max-width: 768px) {
  .innerSearchResult {
    width: 100%;
  }
}

.innerSearch {
  padding-left: 16px;
}

.highest {
  padding-left: 30px;
}

.range {
  padding-left: 30px;
}

.range p {
  font-size: 16px;
  font-weight: 600;
}
.lead-1{
  font-size: 20px!important;
  color:#555555
}

.range p span {
  font-weight: 300;
}

.highest p {
  font-size: 16px;
  font-weight: 600;
}

.highest p span {
  font-weight: 300;
}
.orientation-colors {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}
.orientation-colors .orientation{
  color:rgb(99, 98, 98)
}

.tit {
  font-size: 18px;
  font-weight: 700;
}

.seperator {
  margin: 14px 0;
  height: 2px;
  width: 100%;
  background-color: black;
  opacity: 0.4;
}

.loggedIn p {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.p2 {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resolution {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.cases-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.cases-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.sort-select {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 0.875rem;
}

.case-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.case-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
}

.case-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.case-list span {
  text-transform: capitalize;
}
.case-title {
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;

  font-size: 1rem;
}

.case-resolution {
  color: #4b5563;
  font-size: 0.875rem;
}

.case-content {
  font-size: 0.875rem;
  color: #4b5563;
}

.case-metadata {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.case-metadata span {
  position: relative;
}

/* .case-metadata span:not(:last-child)::after {
    content: "•";
    margin-left: 0.5rem;
  } */

.case-description {
  font-size: 16px;
  margin: 0.4rem 0;
  word-wrap: break-word;
  line-height: 1.1;
}

.case-award {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: medium;
  color: #111827;
}

.alert-msg{
  text-align: center;
  font-weight: 500;
  font-size: 1.4rem;
}