
.access-card {
  /* background-color: white; */
  padding: 2rem;
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.access-card h2 {
  margin: 0 0 1.5rem;
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.access-card form {
  text-align: center;
}
.error-message {
  color: #ff4757;
  padding: 0.75rem;
  min-height: 18px;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  font-size: 0.975rem;
  font-weight: 500;
}

.input-group input {
  width: 350px;
  margin: auto;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.input-group input:focus {
  outline: none;
  border-color: #3498db;
}

.input-group input.input-error {
  border-color: #ff4757;
}

.submit-button {
  text-align: center;
  width: fit-content;
  padding: 0.875rem;
  background-color: #3498db;
  color: white;
  border: none;
  margin: auto;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.submit-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.submit-button.loading {
  background-color: #95a5a6;
  cursor: wait;
}

.form-footer {
  margin-top: 1.5rem;
  text-align: center;
  color: #7f8c8d;
  font-size: 0.75rem;
}

.form-footer a{
  color: #757f80;;
}
@media (max-width: 640px) {
  .access-container {
    margin: 2rem auto;
  }

  .access-card {
    padding: 1.5rem;
  }
}
