.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem 0;
  list-style: none;
  padding: 0;
}

.pagination-page,
.pagination-break,
.pagination-prev,
.pagination-next {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.5rem;
  border-radius: 8px;
  background-color: white;
  color: #3c3d3d;
  font-size: 0.875rem;
    cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-link {
  display: block; /* Makes the entire box clickable */
  width: 100%; /* Ensures it covers the entire button */
  height: 100%; /* Ensures the clickable area covers height */
  text-align: center;

  cursor: pointer;
  line-height: 2rem; /* Vertically center the number */
}

.pagination-page:hover,
.pagination-break:hover,
.pagination-prev:hover,
.pagination-next:hover {
  border: none;
  background-color: #7d8086;

  color: #1f2937;
}

.pagination-active {
  background-color: #898c94;
  
  width: fit-content;
  color: #3a3a3a;
}

.pagination-active:hover {
  background-color: #7d8086;
 
}

.pagination-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.activex{
  color: #1f2937;
}
.pagination-prev,
.pagination-next {
  padding: 0 1rem;
  font-weight: 500;
}

.pagination-break {
  background: transparent;
  border: none;
  color: #6b7280;
}
