nav {
  max-width:  1500px;
  margin: auto;
  background-color: white;
  /* add blurry background using backdrop  */


  padding: 0.75rem 1.5rem;
  /* border-bottom: 1px solid #e5e7eb; */
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.nav-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  display: flex;
  
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.nav-logo img {
  height: 50px;
  width: auto;
}


.nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dashboard {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.credits-display {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

 
  width: fit-content;
}
.credits-count {
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  color: #000043;
}

.credits-label {
  font-size: 0.75rem;
  color: #6b7280;
  text-transform: uppercase;
}


.dashboard .nav-link {
  text-decoration: none;
  font-weight: 500;
  color: #000043;
  cursor: pointer;
  transition: color 0.2s ease;
}

.dashboard .nav-link:hover {
  color: #ff8f94;
}

.login-button {
  color: #4b5563;
  background: none;
  border: none;
}

.login-button:hover {
  color: #1f2937;
}

.logout-button {
  color: #dc2626;
  background: #fee2e2;
  border: 1px solid #fecaca;
}

.logout-button:hover {
  background: #fecaca;
}

.access-link {
  text-decoration: none;
  color: #000043;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;
}

.access-link:hover {
  color: #ff8f94;
}
