/* Center the form and give it a clean look */


/* Form container styling */
.search-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
;

  text-align: center;
}

/* Form title styling */
.form-title {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Styling the row that contains the inputs */
.form-row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 10px;
}

/* change direction in small screens */

@media (max-width: 800px) {
  .form-row {
    flex-direction: column;
  }
}

/* Styling the text input field */
.text-input {
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  width: 100%;
}
/* focuse text-input */
.text-input:focus {
  border: 1px solid #4ca9e7;
}
.form-row .search-note {
  position: absolute;
  font-size: 13px;
  color: rgb(102, 102, 102);
  right: 12px;
  bottom: 1px;
}

/* Search button styling */
.search-button {
  text-align: center;
  width: 90px;
  padding: 0.875rem;
  background-color: #000043;
  color: white;
  border: none;
  /* margin: auto; */
  border-radius:7px;
  font-size: 1.055rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #ff8f94;
}

.search-button:disabled{
  background-color: #ccc;
  cursor: default;
}
/* Hover effect for search button */


/* Error message styling */
.error-text {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}


/* Existing styles remain the same */

.alert {
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.5;
}

.alert-error {
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  color: #991b1b;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.text-input.disabled {
  background-color: #f5f5f5;
}

.btn.disabled {
  background-color: #cccccc;
  border-color: #cccccc;
}

/* Optional hover effect for the alert */
.alert-error:hover {
  background-color: #fee2e2;
}