/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

/* 
nav {
  background-color: #ffffff;
  color: #000;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 2px -2px gray;
}

nav img {
  height: 50px;
}

nav div {
  display: flex;
  gap: 1rem;
}

nav a {
  color: #000;
  text-decoration: none;
}

nav button {
  background-color: #000043;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

nav button:hover {
  background-color: #d94378;
} */

/* Header */

.ml-2 {
  margin-left: 20px !important;
}
.btn {
  text-align: center;
  width: fit-content;
  padding: 0.875rem;
  background-color: #000043;
  color: white;
  border: none;
  /* margin: auto; */
  border-radius:7px;
  font-size: .9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn:hover {
  background-color: #ff8f94;
}

.btn:disabled{
  background-color: #ccc;
  cursor: default;
}

.bg-gray{
  background-color: #898c94!important;
}


.container {

  max-width: 1000px;
  margin: 2px auto;
  padding: .5rem;
}
/* use media queries to make the container responsive */
/* @media (max-width: 600px) {
  .container {
    min-width: fit-content;
  }
}

@media (max-width: 1024px) {
  .container {
    min-width: fit-content;
  }
} */
.btn:disabled {
  background-color: #ccc;
}


/* Form Styles */
/* Form styles for horizontal layout */
/* .form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px; 
}

.form-row select,
.form-row input {
  width: 48%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
} */
/* 
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
} */
/* 
form input,
form select,
form button {
  padding: 0.8rem;
  margin: 0.5rem;
  width: 400px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

form input::placeholder {
  font-size: 14px;
  color: #999;
}

.text-cap{
  text-transform: capitalize;
}

form button {
  background-color: #000043;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

form button:hover {
  background-color: #d94378;
} */

/* Footer Styles */
footer {
  background-color: #f4f4f4;
  padding: 1rem;
  text-align: center;
  color: #666;
}


/* Mobile Styles */
@media (max-width: 600px) {
  form input,
  form select,
  form button {
    width: 100%;
  }

 
}
