/* src/components/Dashboard/Dashboard.css */

.dashboard-container {
    
    margin-top: 20px!important;
    padding: 20px;
    /* background-color: #f4f6f9; */
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .progress-bar-container {
    width: 80%;
    position: relative;
    margin: auto;
    height: 22px;
    background-color: #999999;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #000043 ;
    transition: width 0.5s ease;
  }
  .progress-bar-container span{
    color: white;
    font-size: 14px;
    padding: 2px;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 1px;
    left: 0;
  }
  
 
  .test_mail{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .test-mail-btn:hover {
    background-color: #0056b3;
  }
  
  .referral-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .referral-link-input {
    width: 70%;
    padding: 10px;
    margin-right: 10px;
    /* margin-bottom: 10px; */
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .p1{
    font-size: 14px;
  }

  .dashboard-container p{
    font-size: 15px;
  }
  
  .copy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .copy-btn:hover {
    background-color: #218838;
  }
  
  .invite-friends-section {
    margin-top: 20px;
  }
  
 
  .btn-social{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .dashboard-container {
      padding: 15px;
      max-width: 90%;
    }
  
    h2 {
      font-size: 1.8rem;
    }


  
    p {
      font-size: 1rem;
    }
  
    .referral-link-input {
      width: 100%; /* Full width on smaller screens */
      margin-bottom: 10px;
    }
  
    .copy-btn {
      width: 100%; /* Full width button on smaller screens */
      margin-top: 10px;
    }
  
   
  }
  
  @media (max-width: 480px) {
    h2 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  
    .referral-section {
      flex-direction: column; /* Stack input and button vertically on very small screens */
    }
  
    .invite-friends-section button {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
  